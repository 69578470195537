import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import Modal from 'react-modal'
import amplitude from 'amplitude-js'

import styles from './main_layout.module.css'


const ListLink = props => (
  <li className={styles.listLink}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)


function isEmailValid(email) {
  // Source: https://www.w3resource.com/javascript/form/email-validation.php
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
}


export default class MainLayout extends React.Component {
  constructor(props) {
    super(props);

    this.showCortadoModal = this.showCortadoModal.bind(this);
    this.hideCortadoModal = this.hideCortadoModal.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleEmailBlur = this.handleEmailBlur.bind(this);
    this.handleCortadoSignUpClick = this.handleCortadoSignUpClick.bind(this);

    this.state = {
      cortadoModalShowing: false,
      email: '',
      emailValidationErrorShowing: false,
    }
  }

  componentDidMount() {
    Modal.setAppElement('#root');
    amplitude.getInstance().init('b743ec06d753e6f4767b6993243c7b14');
  }

  hideCortadoModal() {
    this.setState({cortadoModalShowing: false});
    document.body.style.overflow = 'auto';
    amplitude.getInstance().logEvent('hideCortadoModal');
  }

  showCortadoModal() {
    this.setState({cortadoModalShowing: true});
    amplitude.getInstance().logEvent('showCortadoModal');
  }

  handleEmailChange(event) {
    let newEmailValidationErrorShowing = false;
    if (this.state.emailValidationErrorShowing) {
      newEmailValidationErrorShowing = !isEmailValid(event.target.value);
    }
    this.setState({
      email: event.target.value,
      emailValidationErrorShowing: newEmailValidationErrorShowing
    });
  }

  handleEmailBlur(event) {
    this.setState({emailValidationErrorShowing: !isEmailValid(this.state.email)});
  }

  handleCortadoSignUpClick(event) {
    if (!isEmailValid(this.state.email)) {
      event.preventDefault();
      return;
    }
    amplitude.getInstance().logEvent('clickToCortado');
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>when you might die</title>
        </Helmet>
        <div id='root'>
          <Modal
            className={styles.cortadoModal}
            isOpen={this.state.cortadoModalShowing}
            onAfterOpen={() => {document.body.style.overflow = 'hidden';}}
            onRequestClose={this.hideCortadoModal}
            overlayClassName={styles.cortadoOverlay}
          >
            <div className={styles.modalTextContainer}>
              <h3 className={styles.cortadoModalHeader}>
                Do you want a daily reminder of the uncertainty of your death?
              </h3>
              <p className={styles.cortadoModalParagraph}>
                You can use a free service called Cortado to get an email every day with a new possible date of death for you.
                Use it as a regular reminder to live your life like it's short and uncertain.
                Unsubscribe whenever you like.
              </p>
              <form action='https://cortadomail.com/quick_register' method='post'>
                <input type='text' name='source' value='wymd' readOnly style={{display: 'none'}}/>
                <input type='text' name='birthYear' value={this.props.birthYear ? this.props.birthYear : undefined} readOnly style={{display: 'none'}}/>
                <input type='text' name='country' value={this.props.country ? this.props.country : undefined} readOnly style={{display: 'none'}}/>
                <input type='text' name='sex' value={this.props.sex ? this.props.sex : undefined} readOnly style={{display: 'none'}}/>
                <div className={styles.signUpContainer}>
                  <div className={styles.cortadoEmailLabel}>Email</div>
                  <input
                    className={styles.cortadoEmailInput}
                    value={this.state.email}
                    onBlur={this.handleEmailBlur}
                    onChange={this.handleEmailChange}
                    type='email'
                    name='email'
                    placeholder='you@example.com'
                  />
                  { this.state.emailValidationErrorShowing
                    ? <div className={styles.emailValidationError}>Please choose a valid email address.</div>
                    : null
                  }
                  <input
                    className={styles.ctaButton}
                    onClick={this.handleCortadoSignUpClick}
                    type='submit'
                    value='Sign up'/>
                </div>
              </form>
            </div>
            <img
              className={styles.closeIcon}
              src='/close-icon.svg'
              alt='close'
              onClick={this.hideCortadoModal}
            />
          </Modal>
          <header className={styles.header}>
            <Link to="/">
              <div className={styles.logo}>
                when you might die
              </div>
            </Link>
            <ul className={styles.navLinks}>
              <ListLink to="/about/">About</ListLink>
              <li>
                <div
                  className={styles.ctaButton}
                  onClick={this.showCortadoModal}
                >
                  Get a daily reminder
                </div>
              </li>
            </ul>
          </header>
          <div className={styles.main}>
            {this.props.children}
          </div>
          <div className={styles.lowerCtaButtonContainer}>
            <div
              className={styles.ctaButton}
              style={{display: 'inline-block'}}
              onClick={this.showCortadoModal}
            >
              Get a daily reminder
            </div>
          </div>
          <footer className={styles.footer}>
            Created by <a target='_' href='https://bbirnbaum.com'>Ben Birnbaum</a>.
          </footer>
        </div>
      </>
    )
  }
}
